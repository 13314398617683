[data-kit-theme='default'] .ant-table table tbody > tr > td {
  padding: 4px 16px;
}

[data-kit-theme='default'] .ant-table table tbody > tr:hover > td {
  background: #f5f5f5;
}

.cursor-pointer {
  cursor: pointer;
}

[data-kit-theme='default'] .ant-btn .anticon {
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-kit-theme='default'] .ant-select:not(.ant-select-customize-input).select-filter-role {
  .ant-select-selector {
    height: 100%;
  }

  .ant-select-selection-item {
    padding: 4px 0;
  }
}

.bg-inactive-row {
  background-color: #f7a3b2;
}
